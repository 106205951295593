<template>
  <router-link
    v-if="to"
    :to="to"
    :disabled="disabled"
    :type="type"
    :class="[sizeClass, variantClass]"
    class="button"
  >
    <slot></slot>
  </router-link>

  <a
    v-else-if="href"
    :href="href"
    target="_blank"
    rel="noopener noreferrer"
    :class="[sizeClass, variantClass]"
    class="button"
  >
    <slot></slot>
  </a>

  <button
    v-else
    :disabled="disabled"
    :type="type"
    :class="[sizeClass, variantClass]"
    class="button"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },

    size: {
      default: 'small',
      type: String,
    },

    type: {
      default: 'button',
      type: String,
    },

    variant: {
      default: 'secondary',
      type: String,
    },

    to: {},
    href: String,
  },

  computed: {
    sizeClass() {
      switch (this.size) {
        case 'large':
          return 'button--large';
        case 'medium':
          return 'button--medium';
        case 'small':
          return 'button--small';
        case 'xsmall':
          return 'button--xsmall';
        default:
          return 'button--small';
      }
    },
    variantClass() {
      switch (this.variant) {
        case 'primary':
          return 'button--primary';
        case 'secondary':
          return 'button--secondary';
        case 'danger':
          return 'button--danger';
        case 'text':
          return 'button--text';
        case 'secondary-text':
          return 'button--secondary-text';
        default:
          return 'button--secondary';
      }
    },
  },
};
</script>

<style lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  font-weight: 600;
  text-align: center;
  border-radius: 3.5px;
  transition: background-color 300ms, box-shadow 300ms;

  &:disabled {
    color: var(--font-color-100);
    background-color: var(--gray-color-200);
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0 0 0 4px var(--main-color-100);
  }

  &--xsmall {
    min-width: 41px;
    padding: 0.75rem;
    font-size: 0.875rem;
    height: 43px;
  }

  &--small {
    min-width: 80px;
    padding: 0.75rem;
    font-size: 0.875rem;
    .unicon svg {
      height: 16px;
      width: 16px;
    }
  }

  &--medium {
    min-width: 120px;
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  &--large {
    min-width: 150px;
    padding: 0.75rem;
    font-size: 1.075rem;
  }

  &--primary {
    color: white;
    background-color: var(--main-color-500);

    &:not(:disabled):hover {
      background-color: var(--main-color-600);
    }

    &:active {
      background-color: var(--main-color-700);
    }
  }

  &--secondary {
    color: var(--main-color-500);
    border: solid 1px var(--main-color-500);
    background-color: white;

    &:not(:disabled):hover {
      background-color: var(--main-color-100);
    }

    &:active {
      background-color: var(--main-color-200);
    }
  }

  &--text {
    color: var(--main-color-500);

    &:not(:disabled):hover {
      // background-color: var(--gray-color-200);
      text-decoration: underline 1.2px;
    }

    &:active {
      // background-color: var(--gray-color-300);
    }
  }

  &--secondary-text {
    height: 35px;
    min-height: 35px;
    color: var(--font-color-100);
    font-weight: var(--regular);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.375rem;
    line-height: 1rem;
    &:not(:disabled):hover {
      background-color: var(--gray-color-100);
    }
  }

  &--danger {
    color: var(--danger-color-400);
    box-shadow: 0 0 0 0.75px currentColor inset;

    &:not(:disabled):hover {
      background-color: var(--danger-color-100);
    }
  }
}
</style>
