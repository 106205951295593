<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[{ label: 'Estructura Salarial Interna', to: '/estructura-salarial-interna' }]"
          :currentView="{ label: 'Empleados' }"
        />
        <h2>Empleados: {{ position.name }}</h2>
      </div>
      <div class="content__buttons">
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="downloadExcel">
              <unicon
                width="16px"
                height="16px"
                name="file-download"
                fill="var(--font-color-700)"
              ></unicon>
              Descargar Excel
            </menu-item>
          </template>
        </Menu>
      </div>
    </div>
    <div class="filter__container">
      <Menu direction="below" :closeOnItemClick="false">
        <template #label>
          <filter-item :filter="incomeFilter" />
        </template>
        <template #options>
          <menu-item
            v-for="option in [...incomeFilter.options]"
            @click="
              option.active = !option.active;
              filterEmployees();
            "
            :key="option.id"
          >
            {{ option.name }}
            <unicon v-if="option.active" width="16px" height="16px" name="check" fill=""></unicon>
          </menu-item>
        </template>
      </Menu>
      <Filters
        @filter="
          (activeFilters) => {
            filters = activeFilters;
            filterEmployees(activeFilters);
          }
        "
        :filters="categories"
        :filtersOptions="options"
        ref="filters"
      />
    </div>
    <div class="content">
      <table ref="table">
        <tr>
          <th rowspan="2">#</th>
          <th rowspan="2">Nombre</th>
          <th rowspan="2">Nivel laboral</th>
          <th rowspan="2">Fecha de ingreso</th>
          <th rowspan="2">Género</th>
          <th rowspan="2">Mediana</th>
          <th rowspan="2">Sueldo</th>
          <th rowspan="2">
            <div
              :style="{
                display: 'flex',
                'flex-flow': 'column',
              }"
            >
              <span>Estructura Salarial</span>
              <span>(Min - Max)</span>
            </div>
          </th>
          <th rowspan="2">Status Banda Salarial</th>
          <th colspan="2" class="center">Status mediana</th>
        </tr>
        <tr class="second__header">
          <th>Mediana</th>
          <th>%&lt;% Mediana</th>
        </tr>
        <tr
          v-for="(employee, index) in filteredEmployees"
          :key="employee.id"
          :set="
            (value =
              employee.status === 'over'
                ? company.salaryEquityCongif.max / 100
                : employee.status === 'below'
                ? company.salaryEquityCongif.min / 100
                : 1)
          "
        >
          <td>{{ index + 1 }}</td>
          <td>{{ employee.name }}</td>
          <td>{{ options.find(({ id }) => id === employee.nivellaboral).name }}</td>
          <td>{{ timeStampToDMY(employee.entryDate) }}</td>
          <td>{{ employee.gender }}</td>
          <td>{{ meanAndMedians.medians.toFixed(2) }}</td>
          <td>{{ employee.salary.toFixed(2) }}</td>
          <td>
            <span class="status__cell" :class="`status__cell--yellow`">{{
              `[${
                position.payEquity && position.payEquity.min && position.payEquity.min.isActive
                  ? position.payEquity.min.value.toFixed(0)
                  : ((meanAndMedians.medians * company.salaryEquityCongif.min) / 100 || 0).toFixed(
                      0,
                    )
              } - ${
                position.payEquity && position.payEquity.max && position.payEquity.max.isActive
                  ? position.payEquity.max.value.toFixed(0)
                  : ((meanAndMedians.medians * company.salaryEquityCongif.max) / 100 || 0).toFixed(
                      0,
                    )
              }]`
            }}</span>
          </td>
          <td class="status__flex">
            <span class="status__cell" :class="`status__cell--${employee.status}`">
              {{
                (
                  Math.abs(
                    (employee.salary - meanAndMedians.medians * value) /
                      (meanAndMedians.medians * value),
                  ) * 100
                ).toFixed(2)
              }}%
            </span>
            <span
              :title="
                employee.status === 'below'
                  ? 'por debajo de la banda salarial'
                  : employee.status === 'over'
                  ? 'por encima de la banda salarial'
                  : 'dentro de la banda salarial'
              "
            >
              <unicon
                :name="
                  employee.status === 'below'
                    ? 'arrow-down'
                    : employee.status === 'over'
                    ? 'arrow-up'
                    : 'check'
                "
                fill="currentColor"
                width="16px"
                height="16px"
            /></span>
          </td>
          <!-- <td>{{ Math.abs(employee.salary - meanAndMedians.medians * value) }}</td> -->

          <td>
            <span class="status__cell" :class="`status__cell--${employee.status2}`">
              {{
                (
                  Math.abs((employee.salary - meanAndMedians.medians) / meanAndMedians.medians) *
                  100
                ).toFixed(2)
              }}%
            </span>
          </td>
          <td>
            <span class="status__cell" :class="`status__cell--${employee.status3}`">
              {{
                (
                  Math.abs((employee.salary - meanAndMedians.medians) / meanAndMedians.medians) *
                  100
                ).toFixed(2)
              }}%
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex';

import { mean, median } from 'mathjs';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Filters from '@/components/filters/Filters.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import Button from '@/components/buttons/Button.vue';
import { timeStampToDMY } from '@/dateFormats';
import * as XLSX from 'xlsx';
import * as XLSXS from 'xlsx-style';

export default {
  components: {
    Breadcrumbs,
    Menu,
    MenuItem,
    FilterItem,
    Filters,
    Button,
  },
  data() {
    return {
      mean,
      median,
      timeStampToDMY,
      filteredEmployees: [],
      filters: [],
      incomeFilter: {
        id: 'incomeId',
        name: 'Ingresos',
        options: [
          {
            id: 'miniumWage',
            name: 'Sueldo base',
            active: true,
            index: 0,
          },
          {
            id: 'otherIncomes',
            name: 'Otros ingresos',
            active: false,
            index: 1,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company,
    }),
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
    }),
    ...mapState({
      employees(state) {
        const id = this.$route.params.positionId;
        return state.employees.employees.filter(({ puesto }) => puesto === id);
      },
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
    meanAndMedians() {
      const salaries = this.filteredEmployees.map(({ salary }) => salary);
      const { payEquity } = this.position;
      const isMedianActive = payEquity && payEquity.median && payEquity.median.isActive;
      const isMeanActive = payEquity && payEquity.mean && payEquity.isActive;
      const medians = isMedianActive ? payEquity.median.value : this.median(salaries);
      const means = isMeanActive ? payEquity.mean.value : this.mean(salaries);
      return { medians, means };
    },
    position() {
      return this.positions.find(({ id }) => id === this.$route.params.positionId);
    },
  },

  methods: {
    ...mapActions('jobEvaluation', ['getPositions']),
    ...mapActions('employees', ['fetchEmployees']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('options', ['fetchOptions']),

    saveBlobAsFile(name, blob) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    downloadExcel() {
      const $table = this.$refs.table;
      const sheet = XLSX.utils.table_to_sheet($table);

      const workbook = {
        SheetNames: ['estadisticas'],
        Sheets: { estadisticas: sheet },
      };

      workbook.SheetNames.forEach((sheetName) => {
        const ws = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(ws['!ref']);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: 0, c: C });
          if (!ws[address]) continue;
          ws[address].s = { fill: { fgColor: { rgb: '1A96FC' } } };
        }
      });

      const workbookout = XLSXS.write(workbook, {
        bookType: 'xlsx',
        type: 'binary',
      });

      this.saveBlobAsFile(
        `estadisticas.xlsx`,
        new Blob([this.s2ab(workbookout)], { type: 'application/octet-stream' }),
      );
    },

    getStatus(salary) {
      const y = this.meanAndMedians.medians;
      if (salary > y * (this.company.salaryEquityCongif.max / 100)) return 'over';
      if (salary < y * (this.company.salaryEquityCongif.min / 100)) return 'below';
      return 'between';
    },

    getStatus2(salary) {
      const y = this.meanAndMedians.medians;
      if (salary > y) return 'over';
      if (salary < y) return 'below';
      return 'between';
    },

    getStatus3(salary) {
      const y = this.meanAndMedians.medians;
      if (salary > y * (this.company.salaryEquityCongif.posMax / 100)) return 'over';
      if (salary < y * (this.company.salaryEquityCongif.posMin / 100)) return 'below';
      return 'between';
    },

    openConfigModal() {
      this.$refs.modal__config.open();
    },

    filterEmployees(filters = []) {
      const activeFilters = [];
      const employees = JSON.parse(JSON.stringify(this.employees));
      filters.forEach((filter) => {
        const activeOptions = filter.options
          .filter((option) => option.active)
          .map((option) => option.id);

        if (activeOptions.length) {
          activeFilters.push({
            id: filter.id,
            type: filter.type,
            options: activeOptions,
          });
        }
      });

      this.filteredEmployees = employees.filter((employee) => {
        for (let index = 0; index < activeFilters.length; index += 1) {
          const filter = activeFilters[index];
          if (!filter.options.includes(employee[filter.id])) {
            return false;
          }
        }
        return true;
      });
      this.filteredEmployees.forEach((employee, index) => {
        const mult = this.company.salaryEquityCongif.multiplier || 1;
        const salary = mult * this.getTotalIncome(employee.miniumWage, employee.otherIncomes);
        this.filteredEmployees[index].salary = salary;
      });
      this.filteredEmployees.forEach((employee, index) => {
        this.filteredEmployees[index].status = this.getStatus(employee.salary);
        this.filteredEmployees[index].status2 = this.getStatus2(employee.salary);
        this.filteredEmployees[index].status3 = this.getStatus3(employee.salary);
      });
    },

    getTotalIncome(miniumWage, otherIncomes) {
      const accOtherIncomes = otherIncomes.reduce((acc, val) => acc + val, 0);
      const activeIncomes = this.incomeFilter.options
        .filter(({ active }) => active)
        .map(({ id }) => id);
      if (activeIncomes.length === 0) return 0;
      if (activeIncomes.length === 2) return accOtherIncomes + miniumWage;
      if (activeIncomes[0] === 'miniumWage') return miniumWage;
      return accOtherIncomes;
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      if (!this.positions.length) await this.getPositions();
      if (!this.employees.length) await this.fetchEmployees();
      if (!this.categories.length) await this.fetchCategories();
      if (!this.options.length) await this.fetchOptions();
      this.filterEmployees();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;
  height: 100%;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}
.filter__container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}
.content {
  width: 100%;
  overflow: auto;
  margin-top: 1rem;
  background-color: white;
  flex-grow: 1;
}
table {
  width: 100%;
  .center {
    text-align: center;
  }

  .table-action {
    cursor: pointer;
    color: var(--main-color-500);
    &:hover {
      text-decoration: underline var(--main-color-500);
    }
  }
}
.status__flex {
  display: flex;
}
.status__cell {
  padding: 0.375rem;
  font-size: 0.75rem;
  font-weight: var(--medium);
  color: var(--font-color-500);
  background-color: var(--gray-color-200);
  border-radius: 4px;
  width: 4rem;
  text-align: center;
  & + span {
    padding: 0.375rem;
    margin-left: 0.5rem;
    border-radius: 4px;
  }
  &--between,
  &--between + span {
    color: var(--success-color-600);
    background-color: var(--success-color-100);
  }

  &--below,
  &--below + span {
    color: var(--danger-color-500);
    background-color: var(--danger-color-100);
  }

  &--over,
  &--over + span {
    color: var(--main-color-500);
    background-color: var(--main-color-100);
  }
  &--yellow {
    width: fit-content;
  }
  &--yellow,
  &--yellow + span {
    color: var(--warning-color-500);
    background-color: var(--warning-color-200);
  }
}
</style>
